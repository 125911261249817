import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Typography } from '@mui/material';
import "./topimage.css";


export default function Topimage() {
  return (
    <>

      <Box>

        <Paper elevation={0} className='topimagedesktop' >
          <img alt = '' src={process.env.PUBLIC_URL + "images/Frontcover.png"} className='imagesize' />
          <div className="text-block">

            <Typography id='topimagetitle'>
            Match Made In Heaven
            </Typography>

          <Typography id='topimagepara'>
          Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore-et-dolore-magna
              -aliqua-Quis-ipsum-suspendisse-ultrices-gravida-Risus-commodo-viverra-maecenas-accumsan-lacus-vel-facilisis
          </Typography>
     
            <Button variant="contained" id='topimageloginbutton' >Explore</Button>
          </div>
        </Paper>

        <Paper elevation={0} className="topimagemobile" >
          <img alt = '' src={process.env.PUBLIC_URL + "images/topimagemobileview.png"} className='imagesize'/>
        </Paper>

      </Box>

      <Box
      className='chatbotIcon'
     
      >
        <Button >
          <img alt = '' src={process.env.PUBLIC_URL + "images/ChatbotIcon.png"} className='imagesize'/>
        </Button>
      </Box>

    </>
  );
}




