import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import "./latestnews.css";

export default function Latestnews() {
  return (
      <>
 
      <span className='latestnewsspan' >
     
    <Card className="cardssection">
      <CardActionArea>
        <CardMedia
          component="img"
          height="180"
          image="../images/Newsone.png"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h7" component="div">
          By <b>Lugna</b> |  20 March 2022
          </Typography><br></br>
          <Typography variant="body2" id='newscardtext'>
          Animated Invitations are the new trend of 2021
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
        Read More
        </Button>
      </CardActions>
    </Card>

    <Card className="cardssectiontwo">
      <CardActionArea>
        <CardMedia
          component="img"
          height="180"
          image="../images/Newstwo.png"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h7" component="div">
          By <b>Lugna</b>  |  20 March 2022
          </Typography><br></br>
          <Typography variant="body2" color="text.secondary" id='newscardtext'> 
          Here are some wedding decoration ideas
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
        Read More
        </Button>
      </CardActions>
    </Card>

    <Card className="cardssection" >
      <CardActionArea>
        <CardMedia
          component="img"
          height="180"
          image="../images/Newsthree.png"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h7" component="div">
          By <b>Lugna</b> |  20 March 2022
          </Typography><br></br>
          <Typography variant="body2" id='newscardtext'>
          Spring 2022 : What to expect this season
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Read More
        </Button>
      </CardActions>
    </Card>
    </span>
    </>
  );
}
