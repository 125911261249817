import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { ImageListItem, } from '@mui/material';
import "./weddinginvitations.css";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

export default function Weddinginvitations() {
  return (
    <Box className="weddinginvitationboxmain" >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Item >
            <Paper elevation={0}  >
              <ImageListItem>
                <img alt = '' src={process.env.PUBLIC_URL + "images/weddinginvitations.png"} />
              </ImageListItem>
            </Paper>
          </Item>

        </Grid>

        <Grid item xs={12} md={6} className="weddingbox">


          <Item >
            <div >
              <Typography>
              WEDDING INVITATIONS
                </Typography>
             <Typography>
             For All Devices
               </Typography>
              <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              </Typography>

              <br></br>
              <Link href="#" underline="hover">Explore Wedding Invitations</Link>
            </div>
          </Item>
        </Grid>

      </Grid>
    </Box>
  );
}

