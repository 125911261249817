import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Footer from '../footer/footer';
import background from '../footer/worldmap.png';
import "./support.css";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));




const handleSubmit = (event) => {
  event.preventDefault();
  const data = new FormData(event.currentTarget);
  console.log({
    email: data.get('email'),
    password: data.get('password'),
  });
};



export default function Support() {
  return (
    <>
<section className="bgimage" style={{ backgroundImage: `url(${background})`}}>
    
        <Box className="supportboxmain">
      <Grid container spacing={2} id="gridreverseformobile">

        <Grid item xs={12} md={6} id="textsectionmain">
            <Box  className="supporttextbox">
              <Typography variant='h4' id="headingone">
                How Can We Help?
              </Typography>
              <Typography  id="boxpara" >
                Please select a topic below related to your inquiry. If you
                don't find what you need, fill out our contact form.
              </Typography>
            </Box>

            <Box className="supporttextbox">
              <Typography variant='h5' id="subheadingone">
                Technical Issues
              </Typography>
              <Typography  id="boxpara">
                Facing a technical issue with our product.
              </Typography>
              <hr id='hrline' />
            </Box>

            <Box className="supporttextbox">
              <Typography variant='h5' id="subheadingone">
                Vendor Support
              </Typography>
              <Typography   id="boxpara">
                Request additional support if you are a vendor.
              </Typography>
              <hr id='hrline' />
            </Box>

            <Box className="supporttextbox">
              <Typography variant='h5' id="subheadingone">
                Feedback
              </Typography>
              <Typography id="boxpara">
                Register your valuable feedback about Lugna
              </Typography>
              <hr id='hrline' />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item className="supportinfieldbox"    >
              <Box component="form" noValidate onSubmit={handleSubmit}   >
                <Typography  className="inputenametext" >
                  Name
                </Typography>
                <TextField
                  margin="normal"
                  required
                  id="Name"
                  label="Name"
                  name="Name"
                  autoComplete="Name"
                  autoFocus
                  className="supporttextfield"
                />

                <Typography  className="inputenametext" >
                  E-Mail
                </Typography>

                <TextField
                  margin="normal"
                  required
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  className="supporttextfield"
                />
                
                <Typography className="inputmessagetext">
                  Message
                </Typography>

                <TextField
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={4}
                  className="supporttextfield"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  id='supportsigninbutton'
                >
                  Send Message
                </Button>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </section>
      <Footer />

    </>
  );
}

