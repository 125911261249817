import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import "./createwebsitebottom.css";

const images = [
  {
    url: '../images/Cover.png',
    title: 'Create Website',
    width: '100%',
  },

];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 300,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.01,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '1px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 100%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export default function Createwebsitebottom() {
  return (
    <>

      <Box className='createwebsitebottomboxmain'>

        {images.map((image) => (
          <ImageButton
            focusRipple
            key={image.title}
            style={{
              width: image.width,
              height: '350px'
            }}
          >

            <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />

            <ImageBackdrop className="MuiImageBackdrop-root" />

            <Image>
              <span>
                <h1 id='createwebsitetexth1'>Create Your Free Lugna Website</h1>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className='createlugnabutton'
                  sx={{
                
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}
                >

                  {image.title}
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </span>
            </Image>
          </ImageButton>
        ))}
      </Box>
    </>
  );
}
