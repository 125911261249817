import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import "./footer.css";


export default function Footerservices() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} id='accordianmain' >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className='expandmoreicon' />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography id="footermenutext" >
            Services
          </Typography>

        </AccordionSummary>
        <AccordionDetails >
          <List component="div" disablePadding >
            <ListItemButton >
              <ListItemText primary="Wedding Websites" className='listitemtext' />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton >
              <ListItemText primary="Wedding Invitation" className='listitemtext' />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton >
              <ListItemText primary="Wedding Video" className='listitemtext' />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton >
              <ListItemText primary="Wedding Management" className='listitemtext' />
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} id='accordianmain' >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className='expandmoreicon' />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography id="footermenutext">
            Quick Links
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding>
            <ListItemButton >
              <ListItemText primary="Home" className='listitemtext' />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton >
              <ListItemText primary="About" className='listitemtext' />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton >
              <ListItemText primary="Services" className='listitemtext' />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton >
              <ListItemText primary="Support" className='listitemtext' />
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} id='accordianmain'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className='expandmoreicon' />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography id="footermenutext">
            Site Map
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding>
            <ListItemButton >
              <ListItemText primary="Privacy Policy" className='listitemtext' />
            </ListItemButton>
          </List>
          <List component="div" disablePadding >
            <ListItemButton  >
              <ListItemText primary="Terms Of Use" className='listitemtext' />
            </ListItemButton>
          </List>

        </AccordionDetails>
      </Accordion>

    </div>
  );
}
