import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";
import Typography from '@mui/material/Typography';
import "./testimonial.css";
import Latestnews from "../latestnews/latestnews";


const Card = ({ index }) => (
  <>
    <div className="threeslide">
      <Latestnews />                       {/* ///////////// Latest News component imported here/////////////// */}
    </div>
  
  </>

);


export default function Testimonial() {
  return (
    <>

      <div className="threeslide">
        <div className='blogstext'>
          <Typography variant="h6" id="blogstext" > 
            BLOGS
          </Typography><br />
          <Typography variant="h3" id="latestnewstext">
            Latest News
          </Typography>

          <ReactCaroussel slidesToShow={1} slidesToScroll={1} infinite={true}>
            {Array(3)
              .fill(1)
              .map((_,i) => (
                <Card key={i}/>
              ))}
          </ReactCaroussel>
        </div>
      </div>

    </>
  );
}

