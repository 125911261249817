import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';



const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 100,
    height: 42,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 2,
      margin: 6,
      transitionDuration: '200ms',
      '&.Mui-checked': {
        transform: 'translateX(60px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          label:"iOS",
          backgroundColor: theme.palette.mode === 'dark' ? '#999999':'#4f43e8',
          opacity: 1,
          border: 8,
        
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      mt:2,
      width: 28,
      height: 28,
    },
    '& .MuiSwitch-track': {
      borderRadius: 42 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#999999':'#4f43e8',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 800,
      }),
    },
  }));
  
export default function Togglebutton() {
  return (
    <FormGroup className='iosswitch'>
     
     <FormControlLabel
        control={<IOSSwitch defaultChecked />}

      />
 
    </FormGroup>
  );
}
