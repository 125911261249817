import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { ImageListItem } from '@mui/material';
import "./promotionalpagewebsite.css";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import Container from '@mui/material/Container';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const styles = {
  title: {
    fontSize: 'small'
  }
}

export default function Promotionalpagewebsite() {
  return (
    <div id='promotionalpagebody'>
    <>
 
        <Box id='promotionwebpagetopbox'>

          <Typography
          
            className='protfoliotext'
          >
            Our Portfolio
          </Typography>

          <Stack spacing={2} direction="row" className='websitepagestackmain' >
            <NavLink to="/promotionalpagewebsite" end className="textdecration">
              <Button variant="outlined" id='invitationbutton' underline="none">Website</Button>
            </NavLink>
            <NavLink to="/weddinginvitationpage" end className="textdecration">
              <Button variant="outlined" id='websitebutton' >Invitations</Button>                                                                   
            </NavLink>  

          </Stack>

        </Box>

        {/* /////////////////////////////////////cards starts here//////////////////////// */}
        {/* //////////////////////// Row one//////////////// */}

        <Box className='websitepageboxmain' >
          <Grid container spacing={4}>
            <Grid item xs={6} md={3}>
              <Item id='bartext'>
                <ImageListItem id='bartext'>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websiteone.png"} className="websiteimage"  />
                  <Typography  className="blue-magenta">
                    Autumn
                  </Typography>

                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websitetwo.png"} className="websiteimage" />
                  <Typography className="blue-magenta">
                    Cherry Blossom
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websiteone.png"} className="websiteimage" />
                  <Typography className="blue-magenta" >
                    Jasmine
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websitetwo.png"} className="websiteimage" />
                  <Typography className="blue-magenta">
                    Chocolate
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
          </Grid>
        </Box>

        {/* //////////////////////// Row two//////////////// */}

        <Box className='websitepageboxmain'>
          <Grid container spacing={4}>
            <Grid item xs={6} md={3}>
              <Item id='bartext'>
                <ImageListItem id='bartext'>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websiteone.png"} className="websiteimage" />
                  <Typography className="blue-magenta">
                    Autumn
                  </Typography>

                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websitetwo.png"} className="websiteimage"/>
                  <Typography className="blue-magenta">
                    Cherry Blossom
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websiteone.png"} className="websiteimage" />
                  <Typography className="blue-magenta">
                    Jasmine
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websitetwo.png"} className="websiteimage"/>
                  <Typography className="blue-magenta">
                    Chocolate
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
          </Grid>
        </Box>

        {/* //////////////////////// Row three//////////////// */}

        <Box className='websitepageboxmain'>
          <Grid container spacing={4}>
            <Grid item xs={6} md={3}>
              <Item id='bartext'>
                <ImageListItem id='bartext'>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websiteone.png"}  className="websiteimage" />
                  <Typography className="blue-magenta">
                    Autumn
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websitetwo.png"}  className="websiteimage" />
                  <Typography  className="blue-magenta">
                    Cherry Blossom
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websiteone.png"}  className="websiteimage" />
                  <Typography  className="blue-magenta">
                    Jasmine
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                <ImageListItem>
                  <img alt = '' src={process.env.PUBLIC_URL + "images/websitetwo.png"}  className="websiteimage" />
                  <Typography  className="blue-magenta">
                    Chocolate
                  </Typography>
                </ImageListItem>
              </Item>
            </Grid>
          </Grid>
        </Box>

        {/* ///////////////////Page numbers///////////////////// */}

        <Stack spacing={2} >

          <Pagination count={4} color="primary" className='promotionalpagepagination' />

        </Stack>

        {/* /////////////////Footer Section///////////////////// */}


        <Container id="footercontainer">
          <Box className="footerbox">
            <div className='footerlugnalogo'>
              <img alt = '' src={process.env.PUBLIC_URL + "images/goldlugnalogo.png"} className="footerlugnalogo" />
            </div>

            <Typography className='footerpara'>
              Lugna : Your Wedding Destination. With our love and support we help you on planning the wedding of your dreams come true.

            </Typography>
            <Typography id='footertext' >
              Business Enquiry
            </Typography>

            <div className='footerinputfield'>
              <TextField id="outlined-basic" size="small"
             placeholder="Enter your email here"
                variant="outlined" className='textfield' InputProps={{
                  endAdornment: (
                    <Button >
                      <ArrowCircleRightSharpIcon className='arrowicon' />
                    </Button>
                  ),
                }}>

              </TextField>
            </div>


            <hr className='fullwidthhrline' ></hr>
            <br />
            <span >

              <Typography  className='copyrighttext'>

                <img alt = '' src={process.env.PUBLIC_URL + "images/lugnacopyrightlogo.png"} className='copyrightslogo' /> &ensp;

                Lugna © 2022 copyright all right reserved.

              </Typography>
            </span>
          </Box>


        </Container>
   

    </>
    </div>
  );
}
