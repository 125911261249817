import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "../App.css";
import Navbar from "../components/navbar/navbar";
import Home from "../components/Homepage/home";
import Weddinginvitationpage from "../components/weddinginvitationpage/weddinginvitationpage";
import Promotionalpagewebsite from "../components/weddingwebsitepage/promotionalpagewebsite";
import Cards from "../components/Homepage/cards/cards";
import Createwebsite from "../components/Homepage/createwebsite/createwebsite";
import Support from "../components/supportpage/support";
import Websitebuilderpopup from "../components/websitebuilder/websitebuilderpopup";


function Approuter() {
  return (
    <>

      <Navbar />

      <div className="App">

        <Routes>
          
          <Route path="home/" element={<Home />} />
          <Route path="home/:cards" element={<Cards />} />
          <Route path="home/:createwebsite/" element={<Createwebsite />} />
          <Route path="weddinginvitationpage/" element={<Weddinginvitationpage />} />
          <Route path="promotionalpagewebsite/" element={< Promotionalpagewebsite />} />
          <Route path="supportpage/" element={< Support />} />
          <Route path="websitebuilderpopup/" element={< Websitebuilderpopup />} />

        </Routes>
      </div>
    </>
  );
}


export default Approuter;





