import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Websitebuilderpopup from './websitebuilderpopup';





const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

}));

export default function Addelementselectbox() {
  return (

    <>
      <Box >
        <Grid container spacing={2} className="settingstoprow" >
          <Grid item xs={11} >
            <div>
              <Typography className="addelementtext">
                Add Element
              </Typography>
            </div>
          </Grid>

          <Grid item xs={1} container spacing={4}>
            <div >
              <CloseOutlinedIcon className='closeicon' />
            </div>
          </Grid>
        </Grid>
      </Box>

      <hr className='fullwidthhrline' />


      <Box className='addelementboxcontainer' >
        <Grid container spacing={3} >
        <Grid xs={3}  md={1.6} id="mobileview">
            <Item
            
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button > 
                <img alt = '' src={process.env.PUBLIC_URL + "images/Text.svg"} className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext" >Text</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview" >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button onClick={() => <Websitebuilderpopup />}>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Banner.svg"} className='iconimage' />
              </Button>
            </Item>
            <Typography  className="elementtext"  >Image</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview"  >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Heading.svg"} className='iconimage'/>
              </Button>
            </Item>
            <Typography  className="elementtext"  >Heading</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview"  >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Gallery.svg"}  className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext" >Gallery</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview">
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Text&Photo.svg"} className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext" >Text&Photo</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview" >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Q&A.svg"} className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext" >Q&A</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview" >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Counter.svg"} className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext" >Counter</Typography>
          </Grid>
  

  

          <Grid xs={3} md={1.6}  id="mobileview"  >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Banner.svg"} className='iconimage'  />
              </Button>
            </Item>
            <Typography className="elementtext" >Banner</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview" >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Testimonial.svg"} className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext" >Testimonial</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview">
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Dates.svg"} className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext"  >Dates</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview">
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Locations.svg"}  className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext" >Locations</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview" >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Registry.svg"} className='iconimage' />
              </Button>
            </Item>
            <Typography className="elementtext" >Registry</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview"  >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Slideshow.svg"} className='iconimage'/>
              </Button>
            </Item>
            <Typography  className="elementtext"  >Slideshow</Typography>
          </Grid>

          <Grid xs={3} md={1.6}  id="mobileview"  >
            <Item
              variant="outlined"
              elevation={0}
              className='addelementbuttonhover'
            >
              <Button>
                <img alt = '' src={process.env.PUBLIC_URL + "images/Text&Photo.svg"} className='iconimage'/>
              </Button>
            </Item>
            <Typography  className="elementtext"   >Text&Photo</Typography>
          </Grid>
        </Grid>
      </Box>
      
<div  className="bottomline">
<hr className='fullwidthhrline' />
</div>
    

      <span id='addelementbottombutton'>

        <Button variant="text"
      className='addelementbottombtn'
      
      >Discard</Button>

        <Button variant="contained"
     className='addelementbottomnextbtn'
         
         >Next</Button>
      </span>
    </>

  );
}
