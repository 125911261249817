import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Footerservices from './footerservices';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import Footerservicesdesktop from './footerservicesdesktop';
import Footerquicklinksdesktop from './footerquicklinksdesktop';
import Footersitemapdesktop from './footersitemapdesktop';
import "./footer.css";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
    boxShadow: 'none',
}));
window.isClose = 0;

export default function Footer() {
    return (
        <>
            <Box className='footermainbox'>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Item className='footeritemone'>
                            <span>
                                <Typography className='newslettertext'>
                                    News Letter
                                </Typography>

                                <TextField id="outlined-basic" size="small"
                                    placeholder="Enter your email here"
                                    variant="outlined"
                                    className='footernewslettertextfield'
                                    InputProps={{
                                        endAdornment: (
                                            <Button className='ArrowCircleRightSharpIcon'>
                                                <ArrowCircleRightSharpIcon className="ArrowCircleRightSharpIconcolor" />
                                            </Button>
                                        ),
                                    }}>

                                </TextField>
                            </span>
                            <div className='footervendorsection'>
                                <Typography className='vendortext'>
                                    Vendor
                                </Typography>
                                <Typography className="growwithlugnatext">
                                    Grow with Lugna. Vendors can register and list out their business on our platform.
                                </Typography>
                            </div>

                            <Button className="footersignupbutton" variant="contained">
                                Signup
                            </Button>

                        </Item>
                    </Grid>
                    <Grid item xs={12} md={1.5} className="services" >
                        <Item >
                            <Footerservices />  {/*//////////Footerservices for mobileview component rendered here*/}
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={1.5} className="servicesdesktop">
                        <Item >
                            <Footerservicesdesktop />  {/*//////////Footerservices for desktopview component rendered here*/}
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={1.5} className="quicklinks">
                        <Item>
                            {/* <Footerquicklinks /> //////////Footerquicklinks for mobileview component rendered here */}
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={1.5} className="quicklinksdesktop">
                        <Item>
                            <Footerquicklinksdesktop /> {/*//////////Footerquicklinks for desktopview component rendered here*/}
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={1.5} className="sitemap">
                        <Item className="footersitemapitem">
                            {/* <Footersitemap />   //////////Footersitemap for mobileview component rendered here */}
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={1.5} className="sitemapdesktop">
                        <Item className="footersitemapdesktopitem">
                            <Footersitemapdesktop />   {/*//////////Footersitemap for desktopview component rendered here*/}
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                        <Item className='footerlugnalogoimageitem'>

                            <img alt = '' src={process.env.PUBLIC_URL + "images/goldlugnalogo.png"} className="footerlugnaimage" />
                            <Typography className='footerlungatext' >
                                Lugna : Your Wedding Destination. With our love and support we help you on planning the wedding of your dreams come true.
                            </Typography>

                            <Stack direction="row" spacing={0} className="footerstack">
                                <Button>
                                    <Avatar alt="Remy Sharp" src={process.env.PUBLIC_URL + "images/facebook.png"} className="footeravatar" />
                                </Button>
                                <Button>
                                    <Avatar alt="Travis Howard" src={process.env.PUBLIC_URL + "images/twitter.png"} className="footeravatar" />
                                </Button>
                                <Button>
                                    <Avatar alt="Cindy Baker" src={process.env.PUBLIC_URL + "images/instagram.png"} className="footeravatar" />
                                </Button>
                                <Button>
                                    <Avatar alt="Cindy Baker" src={process.env.PUBLIC_URL + "images/pinterest.png"} className="footeravatar" />
                                </Button>
                            </Stack>

                        </Item>

                    </Grid>
                </Grid>
                <hr className='footerhr'></hr>
                <br />
                <span >

                    <Typography className='copyrighttext'>

                        <img alt = '' src={process.env.PUBLIC_URL + "images/lugnacopyrightlogo.png"} className='copyrightslogo' /> &ensp;

                        Lugna © 2022 copyright all right reserved.

                    </Typography>
                </span>
                <br />
                <br />
            </Box>

        </>
    );
}


