import * as React from "react";
import Cards from './cards/cards';
import Createwebsite from './createwebsite/createwebsite';
import Singleslide from './singleslide/singleslide';
import Createwebsitebottom from './createwebsitebottom/createwebsitebottom';
import Footer from '../footer/footer';
import Topimage from "./topimage/topimage";
import Websiteoptions from "./websiteoptions/websiteoptions";
import Testimonial from "./testimonial/testimonial";
import Slidertestimonial from "./slidertestimonial/slidertestimonial";
import Weddinginvitations from "./weddinginvitations/weddinginvitations";
import Navbar from "../navbar/navbar";


function Home() {
return (
<div className="App">

<Topimage/>
<section id="cardsmenulink">
<Cards />
</section>
<section id="createwebsitemenulink">
<Createwebsite/>
</section>
<Websiteoptions/>
<Weddinginvitations/>
<Testimonial/>
<Singleslide/>
<Slidertestimonial/>
<Createwebsitebottom/>
<Footer/>

</div>
);
}
export default Home; 




