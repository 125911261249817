import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import Websitebuilderpopup from '../../websitebuilder/websitebuilderpopup';
import Addelement from '../../websitebuilder/addelement';
import Textfieldmodal from '../../websitebuilder/Textfieldmodal';
import "./cards.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const styles = {
  title: {
    fontSize: 'small'
  }
}

export default function Cards() {
  return (
    <>

    <Websitebuilderpopup/>        
    <Addelement/>
    <Textfieldmodal/>


      <Typography id='h4heading' >
        WHY CHOOSE LUGNA
      </Typography>

      <Typography id='h1heading'>
        Everything you need is here
      </Typography>

      <Box className='cardsmainbox'>
        <Grid container spacing={4}>
          <Grid item xs={6} md={3}>
            <Item>
              <ImageListItem>
                <img alt = '' src={process.env.PUBLIC_URL + "images/boxone.png"} />
                <ImageListItemBar
                  className='bartext'
                  title={'Wedding Planning'} />
              </ImageListItem>
            </Item>
          </Grid>
          <Grid item xs={6} md={3}>
            <Item>
              <ImageListItem>
                <img alt = '' src={process.env.PUBLIC_URL + "images/boxtwo.png"} />
                <ImageListItemBar
                    className='bartext'
                  title={'Wedding Website'} />
              </ImageListItem>
            </Item>
          </Grid>
          <Grid item xs={6} md={3}>
            <Item>
              <ImageListItem>
                <img alt = '' src={process.env.PUBLIC_URL + "images/boxthree.png"} />
                <ImageListItemBar
                  className='bartext'
                  title={'Wedding Invitation'} />
              </ImageListItem>
            </Item>
          </Grid>
          <Grid item xs={6} md={3}>
            <Item>
              <ImageListItem>
                <img alt = '' src={process.env.PUBLIC_URL + "images/boxfour.png"} />
                <ImageListItemBar
                    className='bartext'
                  title={'Vendor Listing'} />
              </ImageListItem>
            </Item>
          </Grid>
        </Grid>
      </Box>

    </>

  );
}

