import React, { useState } from "react";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./navbar.css";
import { NavLink,Link } from "react-router-dom";
import DrawerComp from "../Homepage/drawer/drawer";


// const PAGES = ["Products", "Services", "AboutUS", "ContactUS"]
const Navbar = () => {

  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

  const [color, setColor] = useState(false)

  const changeColor = () => {
    if (window.scrollY >= 710) {
      setColor(true)
    } else {
      setColor(false)
    }
  }



  window.addEventListener('scroll', changeColor)

  const [value, setValue] = React.useState(0);

  function handleChange(event, value) {
    setValue(value);
  }


  return (
    <React.Fragment >
      <AppBar  id="appbar"  className="tabwidth"  elevation={1}>
        <Toolbar className={color ? ' header-bg' : 'header'} >

        <NavLink to="/home" end className='navlinktextdecoration' >  
          <img alt = '' src={process.env.PUBLIC_URL + "images/goldlugnalogo.png"} className="navbarlugnalogo" />
          </NavLink>
          
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <Tabs
                sx={{ marginLeft: "auto" }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "Gray"
                  }
                }}
                textColor="inherit"
                value={value}
                onChange={handleChange}
                aria-label='scrollable force tabs example'
                scrollButtons='auto'
                variant='scrollable'
                className="tabwidth"
              >
             
                <Tab label="Home" component={Link} to="/home"  />

                <Tab label="about" href="#cardsmenulink"  />

                <Tab label="Services" href="#createwebsitemenulink" />
                  
                <Tab label="Wedding Websites" component={Link} to="/promotionalpagewebsite" />

                <Tab label="Wedding Invitations" component={Link} to="/weddinginvitationpage"  />

                <Tab label="Support" component={Link} to="/supportpage" />

              </Tabs>

              <Button id="navbarloginbutton" variant="contained">
                Login
              </Button>
              <Button  className="getstatedbutton" variant="contained">
                Get Started
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>


    </React.Fragment>
  );
};
export default Navbar;
