import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";
import "./slidertestimonial.css";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";

const Card = ({ index }) => (
  <div className="card" style={{ padding: 0, borderRadius: 0 }}>
    <Box
      className="imageboxslider"
      sx={{
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 280,
          height: 250,
          ml: '0%',
          backgroundColor: 'white',
          padding: '0',
        },
      }}

      id="imagecenter"

    >
      <Paper elevation={0} >
        <img alt = '' src={process.env.PUBLIC_URL + "images/boxthree.png"} className="testimonialimage" />
      </Paper>
      <Paper elevation={0} id="testimonialcontent" >
        <Typography variant="h5" id='testimonialboxname'>
          Rithu & Vaishnav
        </Typography>
        <Typography variant="h6"  id='testimonialboxplace'>
          Mumbai
        </Typography>
        <Typography variant="" className='testimonialbox'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Quis ipsum
        </Typography>
      </Paper>
    </Box>
  </div>
);




export default function Slidertestimonial() {
  return (
    <div className="container"  >

      <Typography variant="h7"  className="testimonialtext">
        TESTIMONIALS
      </Typography><br />
      <Typography variant="h4" className="subtestimonialtext">
        See What Others Say
      </Typography>

      <ReactCaroussel
        infinite={true}
        autoplay={true}
        speed={4} //transimition speed -  4 seconds /////
        display={{
          dots: true,
          arrows: false
        }}
      >
        <Card />
        <Card />
        <Card />
   
      </ReactCaroussel>
    </div>
  );
}

