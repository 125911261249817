import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import "./websiteoptions.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

export default function Websiteoptions() {
  return (
    <Box className="websiteoptionboxmain">
      <Grid container spacing={4}>
        <Grid item xs={6} sm={6} md={3}>
          <Item>
            <Paper elevation={0} >
              <img alt = '' src={process.env.PUBLIC_URL + "images/Iconone.png"} className="optionsimagesize"/>
         
                <Typography className='websiteceationsh2'>
                Easy Creation
                </Typography>
      
                <Typography>
                Lorem ipsum dolor sit amet, elit consectetur elit, 
                  </Typography>
         
            </Paper>
          </Item>
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <Item>
            <Paper elevation={0} >
              <img alt = '' src={process.env.PUBLIC_URL + "images/Icontwo.png"} className="optionsimagesize"/>
              <Typography className='websiteceationsh2'>
                100+ Designs.
                </Typography>
                <Typography>
                Lorem ipsum dolor sit amet, elit consectetur elit
                  </Typography> 
            
            </Paper>
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item>
            <Paper elevation={0} >
              <img alt = '' src={process.env.PUBLIC_URL + "images/Iconthree.png"} className="optionsimagesize" />
              <Typography  className='websiteceationsh2'>
                Responsive
                    
              </Typography>
                <Typography>
                Lorem ipsum dolor sit amet, elit consectetur elit, 
                </Typography>
           
            </Paper>
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item>
            <Paper elevation={0} >
              <img alt = '' src={process.env.PUBLIC_URL + "images/Iconfour.png"} className="optionsimagesize" />
              <div className='websiteceationsh2' >
                <Typography>
                24x7 Support
                </Typography>
              
               <Typography>
               Lorem ipsum dolor sit amet, elit consectetur elit,
                 </Typography>
              </div>
            </Paper>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

