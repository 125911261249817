import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import "./singleslide.css";

export default function Latestnewssingleslide() {
  return (
    <>

      <div className="singleslidecard">
        <Card className="cardssection">
          <CardActionArea>
            <CardMedia
              component="img"
              height="180"
              image="../images/Newsone.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h7" component="div">
                By <b>Lugna</b> |  20 March 2022
              </Typography><br></br>
              <Typography variant="body2" id='newscardtext'>
                Animated Invitations are the new trend of 2021
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary">
              Read More
            </Button>
          </CardActions>
        </Card>


      </div>
    </>
  );
}
