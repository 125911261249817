import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";
import Typography from '@mui/material/Typography';
import Latestnewssingleslide from "../latestnewssingleslide/latestnewssingleslide";
import "./singleslide.css";



const Card = ({ index }) => (
  <>
    <div className="singleslide">
      <Latestnewssingleslide />                       {/* ///////////// Latest News component imported here/////////////// */}
    </div>

  </>

);

export default function Singleslide() {
  return (
    <>

      <div className="singleslide" >
        <div className="sigleslidetext" >
          <Typography variant="h6" className="blogstext">
            BLOGS
          </Typography><br />
          <Typography variant="h5" className="newstext">
            Latest News
          </Typography>
        </div>
        <ReactCaroussel slidesToShow={1} slidesToScroll={1} infinite={true} id="latestnewstext">
          {Array(3)
            .fill(1)
            .map((_,i) => (
              <Card key={i}/>
            ))}
        </ReactCaroussel>

      </div>

    </>
  );
}

