import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import "./weddinginvitationpage.css";
import Container from '@mui/material/Container';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import TextField from '@mui/material/TextField';
import { NavLink } from "react-router-dom";
import Invitationone from './invitationone';
import Invitationtwo from './invitationtwo';
import Invitationvideo from './invitationvideo';






const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent'
}));



export default function Weddinginvitationpage() {
  return (

    <div className="App">
      <div className='promotionalpagebody'>
        <Box id='promotionwebpagetopbox'>

    
          <Typography
            
            id='protfoliotext'
          >
            Our Portfolio
          </Typography>

          <Stack spacing={2} direction="row"  className="stackmain">
            <NavLink to="/promotionalpagewebsite" end className="textdecration" >
              <Button variant="outlined" id='websitebutton' >Website</Button>
            </NavLink>

            <Button variant="outlined" id='invitationbutton'>Invitations</Button>
          </Stack>

        </Box>

        {/*///////////////// Invitations part////////////// */}

        <Box  className="invitationboxmain">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Invitationone />
            

            </Grid>
            <Grid item xs={12} md={4}>
              <Invitationtwo />
        

            </Grid>
            <Grid item xs={12} md={4}>
              <Item sx={{ height: "0%", }}>
                <Invitationvideo />
             
              </Item>

            </Grid>

          </Grid>
        </Box>


        {/* /////////////////Footer Section///////////////////// */}


    
        <Container id="footercontainer">
          <Box className="footerbox">
            <div className='footerlugnalogo'>
              <img alt = '' src={process.env.PUBLIC_URL + "images/goldlugnalogo.png"} className="footerlugnalogo" />
            </div>

            <Typography className='footerpara'>
              Lugna : Your Wedding Destination. With our love and support we help you on planning the wedding of your dreams come true.

            </Typography>
            <Typography id='footertext' >
              Business Enquiry
            </Typography>

            <div className='footerinputfield'>
              <TextField id="outlined-basic" size="small"
             placeholder="Enter your email here"
                variant="outlined" className='textfield' InputProps={{
                  endAdornment: (
                    <Button >
                      <ArrowCircleRightSharpIcon className='arrowicon' />
                    </Button>
                  ),
                }}>

              </TextField>
            </div>


            <hr className='fullwidthhrline' ></hr>
            <br />
            <span >

              <Typography  className='copyrighttext'>

                <img alt = '' src={process.env.PUBLIC_URL + "images/lugnacopyrightlogo.png"} className='copyrightslogo' /> &ensp;

                Lugna © 2022 copyright all right reserved.

              </Typography>
            </span>
          </Box>


        </Container>


      </div>
    </div>
  );
}