import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Togglebutton from './togglebutton';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import "./websitebuilderpopupselectbox.css";





export default function Websitebuilderpopupselectbox() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  return (
    <div >

      <Box >
        <Grid container spacing={2} className="settingstoprow">
          <Grid item xs={11}>
            <div>
              <Typography className="settingstext">
                <SettingsOutlinedIcon className="settiogicon"/>
                Settings
              </Typography>
            </div>
          </Grid>

          <Grid item xs={1} container spacing={1}>
            <div >
              <CloseOutlinedIcon className='closeicon' />
            </div>
          </Grid>
        </Grid>
      </Box>

      <hr className='fullwidthhrline' />
      
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordiangap" variant="outlined" elevation={0} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id='iconsize' />}
          aria-controls="panel1bh-content"
          id='accordainsummarycolor'
        >

          <Typography id="listtext">
            Website Details
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Typography id="listtext" >
            Publish
          </Typography>
          <Togglebutton /> {/*//////////////////Togglebutton here*/}
          <Box
        className='accordianinputfield'
          >
            <Typography id="listtext">Title</Typography>
            <TextField fullWidth label="Enter Title" id="Title" />
          </Box>
          <Box
      className='accordianinputfield'
          >
            <Typography id="listtext">Website URL</Typography>
            <TextField fullWidth label="Enter Website URL" id="Website URL" />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordiangap"  variant="outlined" elevation={0} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id='iconsize' />}
          aria-controls="panel2bh-content"
          id='accordainsummarycolor'

        >
          <Typography  id="listtext">
            Date & Location
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus,
            varius pulvinar diam eros in elit. Pellentesque convallis laoreet
            laoreet.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordiangap" variant="outlined" elevation={0} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id='iconsize' />}
          aria-controls="panel3bh-content"
          id='accordainsummarycolor'
        >
          <Typography id="listtext">
            Welcome Note/Announcements
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordiangap" variant="outlined" elevation={0} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id='iconsize' />}
          aria-controls="panel4bh-content"
          id='accordainsummarycolor'
        >
          <Typography  id="listtext">
            Privacy & Lock
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="accordiangap" variant="outlined" elevation={0} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id='iconsize' />}
          aria-controls="panel3bh-content"
          id='accordainsummarycolor'
        >
          <Typography id="listtext">
            Search Engine
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <hr className='fullwidthhrline' />

      <Button variant="outlined" color="error"  endIcon={<DeleteOutlineIcon />} id="deletebutton">
        Delete Website
      </Button>


 
      <span id='addelementbottombutton'>

        <Button variant="text"
      className='addelementbottombtn'
      
      >Discard</Button>

        <Button variant="contained"
     className='addelementbottomnextbtn'
         
         >Save</Button>
      </span>



    </div>
  );
}




