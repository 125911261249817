import React, { Component } from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";




export default class TextEditor extends Component {


state = {
  editorState: EditorState.createEmpty(),
};

onEditorStateChange = (editorState)  => { 
  this.setState(
    {
      editorState,
    }
  );
};

render(){
const {editorState} = this.state;




   return (
    <div>

<Box sx={{ flexGrow: 1,  }}>
        <Grid container spacing={2} className="settingstoprow" >
          <Grid item xs={11} >
            <div>
              <Typography className="textfieldboxsettingstext" >
              Textarea
              </Typography>
            </div>
          </Grid>

          <Grid item xs={1} container spacing={4}>
            <div >
              <CloseOutlinedIcon className='closeicon' />
            </div>
          </Grid>
        </Grid>
      </Box>

      <hr className='fullwidthhrline'/>

      <Editor
        
    editorState={editorState}
    toolbarClassName="toolbarClassName"
    wrapperClassName="wrapperClassName"
    editorClassName="editorClassName"
    onEditorStateChange={this.onEditorStateChange}
    toolbar={{
      options: ['inline',  'textAlign'],
      inline: { inDropdown: false,},
      textAlign: { inDropdown: false ,},
        
  }}
  
  />
  <div  className="bottomline">
<hr className='fullwidthhrline'  >
        </hr>
</div>

<span className='textfieldboxbottombutton'>


<Button variant="text"
  className='addelementbottombtn'
>Discard</Button>

<Button variant="contained"
      className='addelementbottomnextbtn'
 >Add</Button>
</span>

  </div>
  )
}

}