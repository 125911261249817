import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./drawer.css";




const PAGES = ["Home", "Services", "Wedding Websites", "Wedding Invitations", "Support", "Login", "Logout"]
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerWidth = '100%';

  return (
    <React.Fragment>
      <Drawer
        width="100%"
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#362167' },
        }}
      >
        <MenuIcon onClick={() => setOpenDrawer(false)}  className="menuiconmain" />
        <hr  className="drawerhrline"></hr>
        <List>
        
            <ListItemButton onClick={() => setOpenDrawer(false)} id="drawerlistitembutton" >
              <ListItemIcon>
                <ListItemText className="drawerlistitemtext" >Home</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton onClick={() => setOpenDrawer(false)}   id="drawerlistitembutton" >
              <ListItemIcon>
                <ListItemText  className="drawerlistitemtext" >Services</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton onClick={() => setOpenDrawer(false)}   id="drawerlistitembutton"  >
              <ListItemIcon>
                <ListItemText  className="drawerlistitemtext">Wedding Websites</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton onClick={() => setOpenDrawer(false)}  id="drawerlistitembutton"  >
              <ListItemIcon>
                <ListItemText  className="drawerlistitemtext">Wedding Invitations</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton onClick={() => setOpenDrawer(false)}  id="drawerlistitembutton"  >
              <ListItemIcon>
                <ListItemText  className="drawerlistitemtext">Support</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton  onClick={() => setOpenDrawer(false)}  id="drawerlistitembutton">
              <ListItemIcon>
                <ListItemText  className="drawerlistitemtext">Services</ListItemText>
              </ListItemIcon>
            </ListItemButton>
              
        </List>
      </Drawer>
      <IconButton
      id="iconbutton"
         onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon className="menuicon" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;




