import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import "./footer.css";

export default function Footersitemapdesktop() {
  return (

    <Box className="Footermenumainbox">
      <nav aria-label="main mailbox folders">
        <List>

          <ListItem disablePadding className='footerlistitemmenuname'  >
            <ListItemButton>
              <ListItemIcon>
              </ListItemIcon>
              <ListItemText primary="Sitemap" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      <Divider />
      <nav aria-label="secondary mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#privacy-policy">
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#terms-of-use">
              <ListItemText primary="Terms of Use" />
            </ListItemButton>
          </ListItem>

        </List>
      </nav>
    </Box>
  );
}
